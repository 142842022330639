<template>
  <div>
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default">
            <i
              class="fal fa-address-card"
              style="margin:0 10px 0 0;font-size: 20px"
            ></i>
            <span @click="main()">{{ $t("overtime_money.title") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'setting_ot.create'"
          >
            <span v-show="true">/</span>
            <span>{{ $t("overtime_money.create") }}</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>

    <div class="page-content">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    main() {
      this.$router
        .push({
          name: "setting_ot.index",
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss"></style>
